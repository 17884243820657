import { Link } from "gatsby"
import React, { useState } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import { Container } from "react-bootstrap"
import Select from "react-select"
import usePagination from "../../hooks/usePagination"
import { areaguideURL } from "../../lib/urls"
import InnerPagination from "../InnerPagination/InnerPagination"
import "./AreaGuideListing.scss"

const {
  ImageModule,
} = require("@starberry/gatsby-theme-utils/Modules/ImageModule")

const AreaGuideListing = ({ areaGuideData, strapi_id }) => {
  let allCommunityOption = {
    label: "Select Community",
    value: "all",
  }
  const [search, setSearch] = useState("")
  const [selectedArea, setSelectedArea] = useState(areaGuideData)
  const [selectedOption, setSelectedOption] = useState(allCommunityOption)

  let communityOption = []
  communityOption.push(allCommunityOption)

  areaGuideData.forEach(element => {
    if (element.node.title) {
      communityOption.push({
        label: element.node.title,
        value: element.node.title,
      })
    }
  })

  const handleChange = event => {
    setSearch(event.target.value)
    let areaGuideList = areaGuideData

    if (selectedOption.value !== "all" && event.target.value === "") {
      areaGuideList = areaGuideData.filter(area => {
        if (area.node.title) {
          return area.node.title.includes(selectedOption.value)
        }
      })
    }

    if (selectedOption.value !== "all" && event.target.value) {
      areaGuideList = areaGuideData.filter(area => {
        if (area.node.title) {
          return area.node.title.includes(selectedOption.value)
        }
      })
    }

    if (event.target.value) {
      let areaGuide = areaGuideList.filter((area, index) =>
        area.node?.title
          ?.toLowerCase()
          ?.includes(event.target.value.toLowerCase())
      )
      setSelectedArea(areaGuide)
    } else {
      setSelectedArea(areaGuideList)
    }
  }

  // for pagination
  const itemsPerPage = 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedArea,
    itemsPerPage,
  })
  // for pagination

  const handleTabChange = option => {
    setSearch("")
    setSelectedOption(option)
    if (option.value === "all") {
      setSelectedArea(areaGuideData)
      return
    }
    let filteredArea = areaGuideData.filter(area => {
      if (area.node.title) {
        return area.node.title.includes(option.value)
      }
    })

    setSelectedArea(filteredArea)
    setCurrentPage(1)
  }

  var imagename = "area-guide.tile_image.list_image"

  return (
    <div className="areaguide-listing-wrapper">
      <Container>
        <ScrollAnimation
          className="areaguide-animation"
          animateIn="animate__slideInUp"
          animateOnce
          delay={100}
          offset={100}
        >
          <div className="areaguide-sorting-section">
            <div className="search-box">
              <input
                className="form-control search"
                placeholder="Search Communities"
                onChange={handleChange}
                value={search}
              />
              <span>
                <i className="icon grey-search-icon" />
              </span>
            </div>

            <div className="select-department">
              <Select
                options={communityOption}
                placeholder={"Select Community"}
                isSearchable={false}
                className={"category-select"}
                classNamePrefix={"category-select"}
                //   hideSelectedOptions={true}
                value={selectedOption}
                onChange={handleTabChange}
              />
              <span>
                <i className="icon grey-down-arrow" />
              </span>
            </div>
          </div>
        </ScrollAnimation>
        <div className="areaguide-listing-section">
          {search
            ? selectedArea?.map((data, index) => {
                let processedImages
                processedImages =
                  data?.node?.imagetransforms?.tile_image_Transforms ||
                  JSON.stringify({})
                return (
                  <div className="areaguide-list" key={data?.node?.title}>
                    <div className="image-section">
                      <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                        <ImageModule
                          ImageSrc={data?.node?.tile_image}
                          altText={`${data?.node?.title}`}
                          imagetransforms={processedImages}
                          renderer="pic-src"
                          imagename={imagename}
                          strapi_id={data?.node?.strapi_id}
                          classNames="img-fluid"
                        />
                      </Link>
                      <div className="image-overlay">
                        <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                          <span>
                            <i className="icon white-up-arrow" />
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="content-section">
                      <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                        <h5>{data?.node?.title}</h5>
                      </Link>
                    </div>
                  </div>
                )
              })
            : currentItems?.map((data, index) => {
                let processedImages
                processedImages =
                  data?.node?.imagetransforms?.tile_image_Transforms ||
                  JSON.stringify({})
                return (
                  <ScrollAnimation
                    animateIn="animate__slideInUp"
                    animateOnce
                    delay={index * 100}
                    offset={100}
                  >
                    <div className="areaguide-list" key={data?.node?.slug}>
                      <div className="image-section">
                        <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                          <ImageModule
                            ImageSrc={data?.node?.tile_image}
                            altText={`${data?.node?.title}`}
                            imagetransforms={processedImages}
                            renderer="pic-src"
                            imagename={imagename}
                            strapi_id={data?.node?.strapi_id}
                            classNames="img-fluid"
                          />
                        </Link>
                        <div className="image-overlay">
                          <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                            <span>
                              <i className="icon white-up-arrow" />
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div className="content-section">
                        <Link to={`${areaguideURL}${data?.node?.slug}/`}>
                          <h5>{data?.node?.title}</h5>
                        </Link>
                      </div>
                    </div>
                  </ScrollAnimation>
                )
              })}
        </div>

        <InnerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={selectedArea?.length}
          setCurrentPage={setCurrentPage}
        />
      </Container>
    </div>
  )
}

export default AreaGuideListing
